
exports.separate_orders = (orders) => {
    var results = [];
    console.log("Order:", orders);
    for (var order of orders) {
        // Separate quickpicks
        if (order.ticket.quickPicks > 5) {
            // Add full tickets
            for (var i = 0; i < Math.floor(order.ticket.quickPicks / 5); ++i) {
                results.push({
                    ticket: {
                        multiplay: order.ticket.multiplay,
                        multiplier: order.ticket.multiplier,
                        quickPicks: 5,
                        type_name: order.ticket.type_name,
                    }
                });
            }
            // Add the rest
            if (order.ticket.quickPicks % 5 != 0) {
                results.push({
                    ticket: {
                        multiplay: order.ticket.multiplay,
                        multiplier: order.ticket.multiplier,
                        quickPicks: order.ticket.quickPicks % 5,
                        type_name: order.ticket.type_name,
                    }
                });
            }
        } else if (order.ticket.grids != undefined && order.ticket.grids.length > 5) {
            var new_order = undefined;
            for (var grid of order.ticket.grids) {
                if (new_order == undefined) {
                    new_order = {
                        ticket: {
                            multiplay: order.ticket.multiplay,
                            multiplier: order.ticket.multiplier,
                            grids: [],
                            type_name: order.ticket.type_name,
                        }
                    }
                }
                new_order.ticket.grids.push(grid);

                if (new_order.ticket.grids.length == 5) {
                    results.push(new_order);
                    new_order = undefined;
                }
            }
            if (new_order != undefined) {
                results.push(new_order);
            }
        } else {
            results.push(order);
        }
    }
    console.log("separate_orders: ", results);
    return results;
}


exports.ticket_to_cart = (ticket) => {
    var ticket_info = {
        type_name: ticket.type_name,
        multiplay: ticket.multiplay,
        multiplier: ticket.multiplier,
        grids: []
    };

    for (var number of ticket.numbers) {
        var poolsStr = number.replace("(", "").replace(")", "").split(" ");
        var pool_map = { pool: [] };
        for (var poolStr of poolsStr) {
            var number_map = { numbers: [] };
            var current_pool_str = poolStr.split("-");
            for (var current_number of current_pool_str) {
                number_map.numbers.push(parseInt(current_number));
            }
            pool_map.pool.push(number_map);
        }
        ticket_info.grids.push(pool_map);
    }
    return { ticket: ticket_info, type: "grids" };
}

exports.get_draw_dates = (type_name, first_date, last_date) => {
    var result = [];
    console.log("first_date:", first_date);
    result.push(this.$formatDateToYYYYMMDD(first_date));

    var next_draw_mapping = {
        powerball: {
            1: 2, // If monday, next draw in 2 days
            3: 3, // If wednesday, next draw in 3 days
            6: 2, // If saturday, next draw in 2 days
        },
        megamillions: {
            2: 3, // If tuesday, next draw in 3 days
            5: 4, // If friday, next draw in 4 days
        },
    };
    var next_date = new Date(first_date);
    while (next_date < last_date) {
        var current_day = next_date.getDay();
        var mapped_day_to_add = next_draw_mapping[type_name][current_day];
        if (mapped_day_to_add == undefined) {
            return [];
        }
        next_date.setDate(next_date.getDate() + mapped_day_to_add);
        result.push(this.$formatDateToYYYYMMDD(new Date(next_date)));
    }

    return result;
}