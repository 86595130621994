<template>
  <div>
    <div v-if="order_in_process">
      <v-banner class="accent mb-3 font-weight-black white--text">
        <div class="d-flex jutify-center white--text accent">
          <v-icon class="ma-2 white--text">mdi-information</v-icon>
          <div class="ma-2">
            {{ $t("message.myTicketsPage.orderInProcess") }}
            <a href="/myTickets?tab=orders" class="white--text">
              {{ $t("message.myTicketsPage.orderInProcessLink") }}
            </a>
          </div>
        </div>
        <!-- <v-container>
          <v-row>
            <v-col cols="1">
              <v-icon class="white--text">mdi-information</v-icon>
            </v-col>
            <v-col cols="11">
              {{ $t("message.myTicketsPage.orderInProcess") }}
              <a href="/myTickets?tab=orders" class="white--text">
                {{ $t("message.myTicketsPage.orderInProcessLink") }}
              </a>
            </v-col>
          </v-row>
        </v-container> -->
      </v-banner>
    </div>
    <!-- <div class="emb-card pa-4">
      <router-link to="#tab=orders">
        Voyez vos commande en traitement en cliquant ici
      </router-link>
    </div> -->
    <div
      class="order-history-wrap emb-card pa-4"
      v-if="ticketsData.length != 0"
    >
      <div>
        <v-data-table
          :headers="ticketsHeaders"
          :items="ticketsData"
          hide-default-footer
          disable-sort
          :items-per-page="-1"
        >
          <template v-slot:item.first_draw_date="{ item }">
            {{ formatDate(item.first_draw_date) }}
          </template>
          <template v-slot:item.from="{ item }"
            >{{ item.client_firstname + " " + item.client_lastname }}<br />
            <a :href="'mailto:' + item.client_email">{{ item.client_email }}</a>
          </template>
          <template v-slot:item.type_name="{ item }">
            <v-img width="75" :src="img_mapping[item.type_name]"></v-img>
          </template>
          <template v-slot:item.draw_dates="{ item }">
            <div
              v-if="item.first_draw_date.seconds <= item.last_draw_date.seconds"
            >
              {{ formatDate(item.first_draw_date) }}
              {{ $t("message.myTicketsPage.thru") }}
              {{ formatDate(item.last_draw_date) }}
            </div>
            <div v-else>
              {{ formatDate(item.first_draw_date) }}
            </div>
          </template>
          <template v-slot:item.status="{ item }">
            <div v-for="(validation, key) in getTicketStatus(item)" :key="key">
              {{ validation }}
            </div>
          </template>
          <template v-slot:item.image="{ item }">
            <div class="py-2" v-if="item.multiplay > 1">
              <v-btn @click="replay_ticket_dialog(item)" style="width: 110px">
                {{ $t("message.myTicketsPage.replay") }}
              </v-btn>
            </div>
            <div>
              <v-btn @click="openImage(item)" style="width: 110px"
                >{{ $t("message.myTicketsPage.seeMyTicket") }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.numbers="{ item }">
            <div v-for="(number, index) in item.numbers" :key="index">
              {{ number }}
            </div>
          </template>
        </v-data-table>
      </div>
      <div
        class="text-center mt-4"
        v-if="more_btn_visible"
        @click="fetch_user_tickets()"
        :disabled="!more_btn_enable"
      >
        <v-btn class="accent"> {{ $t("message.myTicketsPage.more") }} </v-btn>
      </div>
    </div>
    <div
      class="order-history-wrap emb-card pa-4"
      v-else-if="ticket_fetched == false"
    ></div>
    <div
      class="order-history-wrap emb-card pa-4"
      v-else-if="image == null && ticket_type == 'bought'"
    >
      {{ $t("message.myTicketsPage.noTicket") }}
    </div>
    <div
      class="order-history-wrap emb-card pa-4"
      v-else-if="image == null && ticket_type == 'gift'"
    >
      {{ $t("message.myTicketsPage.noTicket") }}
    </div>
    <div v-if="image != null" class="img_container_fixed">
      <div class="img_container">
        <v-img :src="image"></v-img>
        <v-btn
          class="mx-2"
          fab
          dark
          top
          absolute
          left
          small
          color="accent"
          @click="image = null"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
        <a :href="image" download="filename.jpg">
          <v-btn class="mx-2" fab dark top right absolute small color="accent">
            <v-icon dark> mdi-download </v-icon>
          </v-btn>
        </a>
      </div>
    </div>
    <emb-delete-confirmation
      ref="replayConfirmationDialog"
      :message="$t('message.myTicketsPage.replayDlgMessage')"
      @onConfirm="replay_ticket"
    >
    </emb-delete-confirmation>
  </div>
</template>

<style scoped>
.img_container_fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}
.img_container {
  position: relative;
  top: 50px;
  width: 100%;
  height: 100%;
  max-width: 400px;
  display: block;
  margin: auto;
  align-content: center;
}
</style>
<script>
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import * as orders_util from "../../services/orders";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUser", "getUserInfo", "general_info"]),
  },
  props: ["ticket_type"],
  data() {
    return {
      limit: 5,
      order_in_process: false,
      image: null,
      ticket_fetched: false,
      ticketsHeaders: [],
      ticketsData: [],
      lastTicket: null,
      more_btn_visible: true,
      more_btn_enable: true,
      selectedItem: null,

      img_mapping: {
        megamillions: "/static/images/megamillions_mini.png",
        powerball: "/static/images/powerball_mini.png",
      },
    };
  },
  watch: {
    "$store.state.user": function () {
      if (this.getUser != undefined && !this.ticket_fetched) {
        this.fetch_user_tickets();
      }
    },
    "$i18n.locale": function () {
      this.updateHeaders();
    },
  },
  mounted() {
    if (this.getUser != undefined && !this.ticket_fetched) {
      this.fetch_user_tickets();
    }
    if (this.getUser != undefined && this.ticket_type == "bought") {
      this.fetch_user_orders();
    }

    this.updateHeaders();
  },
  methods: {
    fetch_user_tickets() {
      var where_prop = "gift_email";
      var where_value = this.getUser.email;
      if (this.ticket_type == "bought") {
        where_prop = "client_id";
        where_value = this.getUser.uid;
      }
      this.more_btn_enable = false;
      const usersTicketsRef = collection(getFirestore(), "users_tickets");
      let q = query(
        usersTicketsRef,
        where(where_prop, "==", where_value),
        orderBy("last_draw_date", "desc")
      );

      if (this.lastTicket != null) {
        q = query(q, startAfter(this.lastTicket));
      }

      q = query(q, limit(this.limit));

      getDocs(q)
        .then((querySnapshot) => {
          this.ticket_fetched = true;
          var documents = [];
          if (querySnapshot.size < this.limit) {
            this.more_btn_visible = false;
          }
          querySnapshot.forEach((doc) => {
            documents.push(doc.data());
            this.lastTicket = doc;
          });
          this.ticketsData.push(...documents);
          this.more_btn_enable = true;
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.more_btn_enable = true;
        });
    },
    formatDate(timestamp) {
      var d = timestamp.toDate();
      const ye = new Intl.DateTimeFormat(this.getUserInfo.language, {
        year: "numeric",
      }).format(d);
      const mo = new Intl.DateTimeFormat(this.getUserInfo.language, {
        month: "short",
      }).format(d);
      const da = new Intl.DateTimeFormat(this.getUserInfo.language, {
        day: "2-digit",
      }).format(d);
      return `${da}-${mo}-${ye}`;
    },
    updateHeaders() {
      this.ticketsHeaders = [
        {
          text: this.$t("message.myTicketsPage.header.lotteryType"),
          value: "type_name",
        },
        {
          text: this.$t("message.myTicketsPage.header.numbers"),
          value: "numbers",
        },
        {
          text: this.$t("message.myTicketsPage.header.drawDate"),
          value: "draw_dates",
        },
        {
          text: this.$t("message.myTicketsPage.header.status"),
          value: "status",
        },
        { text: "", value: "image" },
      ];

      if (this.ticket_type == "gift") {
        this.ticketsHeaders.splice(1, 0, {
          text: this.$t("message.myTicketsPage.header.offerBy"),
          value: "from",
        });
      }
    },
    openImage(ticket) {
      const imageRef = ref(getStorage(), ticket.image_path);

      getDownloadURL(imageRef).then((url) => {
        this.image = url;
      });
    },
    getTypename(item) {
      var result = this.$t("message." + item.type_name);
      if (item.multiplier) {
        result += this.$t(
          "message.myTicketsPage.withMultiplier." + item.type_name
        );
      }
      return result;
    },
    getTicketStatus(item) {
      var result = [];

      if (
        item.validation != undefined &&
        Object.keys(item.validation).length > 0
      ) {
        for (var validation_date_key in item["validation"]) {
          var validation_date = item["validation"][validation_date_key];
          if (validation_date.total_amount == 0) {
            result.push(
              validation_date_key +
                ": " +
                this.$t("message.myTicketsPage.notWinning")
            );
          } else {
            result.push(
              validation_date_key +
                ": " +
                this.$t("message.myTicketsPage.winning") +
                " (" +
                this.$formatMoney(validation_date.total_amount, 2, "USD") +
                ")"
            );
          }
        }
      } else {
        result.push(this.$t("message.myTicketsPage.waitingResult"));
      }
      return result;
    },
    replay_ticket_dialog(item) {
      this.selectedItem = item;
      this.$refs.replayConfirmationDialog.openDialog();
    },
    replay_ticket() {
      var item = this.selectedItem;
      this.$refs.replayConfirmationDialog.close();
      var ticket_info = orders_util.ticket_to_cart(item);
      this.$store.dispatch("add_to_cart", ticket_info).then(() => {
        this.$snotify.success(this.$t("message.productsPage.productAdded"), {
          closeOnClick: false,
          pauseOnHover: false,
          timeout: 1000,
        });
      });
    },
    fetch_user_orders() {
      const ordersRef = collection(getFirestore(), "orders");
      const q = query(
        ordersRef,
        where("client_id", "==", this.getUser.uid),
        where("general_status", "==", "IN_PROCESS"),
        limit(1)
      );

      getDocs(q).then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          this.order_in_process = true;
        }
      });
    },
    disabled_replay(type_name) {
      if (
        this.general_info != null &&
        this.general_info.limits.grids_blocked != undefined &&
        this.general_info.limits.grids_blocked.includes(type_name)
      ) {
        return true;
      } else if (this.general_info != null) {
        return false;
      } else {
        if (this.general_info == null) {
          this.$store.dispatch("fetch_general_info").then(() => {
            this.disabled_replay(type_name);
          });
        }
      }
      // console.log("this.disabled_replay: ", this.disabled_replay);
    },
  },
};
</script>

